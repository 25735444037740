import "./style.css";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
//import * as dat from "dat.gui";

/**
 * Base
 */
// Debug
//const gui = new dat.GUI();

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader();
const matcapTexture = textureLoader.load("/textures/matcaps/10.png");

/**
 * Fonts
 */
const fontLoader = new THREE.FontLoader();
const Material = new THREE.MeshMatcapMaterial({
  matcap: matcapTexture,
});

fontLoader.load("/fonts/helvetiker_regular.typeface.json", (font1) => {
  const textGeometry1 = new THREE.TextBufferGeometry(
    "Almost there \n Stay safe",
    {
      font: font1,
      size: 0.5,
      height: 0.2,
      curveSegments: 12,
      bevelEnabled: true,
      bevelThickness: 0.03,
      bevelSize: 0.02,
      bevelOffset: 0,
      bevelSegments: 3,
    }
  );
  textGeometry1.center();
  const text1 = new THREE.Mesh(textGeometry1, Material);
  scene.add(text1);
});

fontLoader.load("/fonts/Wete_Regular.json", (font2) => {
  const textGeometry2 = new THREE.TextBufferGeometry("Mauro creates", {
    font: font2,
    size: 1,
    height: 0.2,
    curveSegments: 12,
    bevelEnabled: true,
    bevelThickness: 0.02,
    bevelSize: 0.02,
    bevelOffset: 0,
    bevelSegments: 3,
  });
  textGeometry2.center();
  textGeometry2.translate(0, -2.7, 0);
  textGeometry2.rotateZ(-Math.PI / 2);
  const text2 = new THREE.Mesh(textGeometry2, Material);
  scene.add(text2);
});

/**
 * Object
 */

const backgroundObjects = new THREE.Group();
scene.add(backgroundObjects);
const scale = Math.random();

const donutGeometry = new THREE.TorusBufferGeometry(0.2, 0.15, 20, 45);

for (let i = 0; i < 50; i++) {
  const donut = new THREE.Mesh(donutGeometry, Material);
  donut.position.x = (Math.random() - 0.5) * 10;
  donut.position.y = (Math.random() - 0.5) * 10;
  donut.position.z = (Math.random() - 0.5) * 10;

  donut.rotation.x = Math.random() * Math.PI;
  donut.rotation.y = Math.random() * Math.PI;

  donut.scale.set(scale, scale, scale);
  backgroundObjects.add(donut);
}

const icoGeometry = new THREE.IcosahedronGeometry(0.3, 0);

for (let j = 0; j < 100; j++) {
  const ico = new THREE.Mesh(icoGeometry, Material);
  ico.position.x = (Math.random() - 0.5) * 10;
  ico.position.y = (Math.random() - 0.5) * 10;
  ico.position.z = (Math.random() - 0.5) * 10;

  ico.rotation.x = Math.random() * Math.PI;
  ico.rotation.y = Math.random() * Math.PI;

  ico.scale.set(scale, scale, scale);
  backgroundObjects.add(ico);
}

const boxGeometry = new THREE.BoxGeometry(0.3, 0.3, 0.3);

for (let j = 0; j < 100; j++) {
  const box = new THREE.Mesh(boxGeometry, Material);
  box.position.x = (Math.random() - 0.5) * 10;
  box.position.y = (Math.random() - 0.5) * 10;
  box.position.z = (Math.random() - 0.5) * 10;

  box.rotation.x = Math.random() * Math.PI;
  box.rotation.y = Math.random() * Math.PI;

  box.scale.set(scale, scale, scale);
  backgroundObjects.add(box);
}
/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.x = 1;
camera.position.y = 1;
camera.position.z = 2;
scene.add(camera);

/**
 * Audio
 */
// create an AudioListener and add it to the camera
const listener = new THREE.AudioListener();
camera.add(listener);

// create a global audio source
const sound = new THREE.Audio(listener);

// load a sound and set it as the Audio object's buffer
const audioLoader = new THREE.AudioLoader();
audioLoader.load("ambient-1.mp3", function (buffer) {
  sound.setBuffer(buffer);
  sound.setLoop(true);
  sound.setVolume(0.5);
  sound.play();
});

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * Animate
 */
const clock = new THREE.Clock();

const tick = () => {
  const elapsedTime = clock.getElapsedTime();

  // Update controls
  controls.update();

  // Update objects
  backgroundObjects.rotation.x = elapsedTime * 0.02;
  backgroundObjects.rotation.y = elapsedTime * 0.02;

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
